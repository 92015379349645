import { css } from '@emotion/css';
import { DefaultToastOptions } from 'react-hot-toast/src/core/types';

const basicStyles = css`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2.25rem;
  border-radius: 32px;
  max-width: 100%;
  color: #fff;
  font-variation-settings: 'wght' 700, 'wdth' 100, 'opsz' 12;
  font-size: 1em;
  line-height: 1.3;
  letter-spacing: 0;

  @media (min-width: 768px) {
    border-radius: 1.5rem;
  }
`;

export const toasterOptions: DefaultToastOptions = {
  success: {
    icon: null,
    className: css`
      ${basicStyles}
      background-color: #28AC12;
    `,
    duration: 5000,
  },
  error: {
    icon: null,
    className: css`
      ${basicStyles}
      background-color: #EB263D;
    `,
    duration: 5000,
  },
  blank: {
    icon: null,
    className: css`
      ${basicStyles}
      background-color: #000;
    `,
    duration: 5000,
  },
};
