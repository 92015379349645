import axios from 'axios';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLIENT_API_ROUTE,
  headers: { 'Content-Type': 'application/json' },
});

export const httpPost = async ({ url, body = {} }) => {
  const { data } = await client.post(url, body);

  return data;
};

export const httpPut = async ({ url, body = {} }) => {
  const { data } = await client.put(url, body);
  return data;
};

export const httpGet = async (url: string) => {
  const { data } = await client.get(url);
  return data;
};

export const httpDelete = async (url: string) => {
  const { data } = await client.delete(url);
  return data;
};

export const serialize = (raw) => JSON.parse(JSON.stringify(raw));
