import { httpGet, httpPost } from '../../lib/http/request';

import { Talent } from './talent.types';

const BASE_URL = 'talent';

export const getTalent = async (talentId: string | string[]) => {
  const url = `${BASE_URL}/${talentId}`;

  return httpGet(url);
};

// TODO : Remove
export const getMyTalent = async () => {
  const url = `${BASE_URL}`;

  return httpGet(url);
};

export const updateMyTalent = async (talent: Partial<Talent> = {}) => {
  const url = `${BASE_URL}`;

  return httpPost({ url, body: talent });
};

export const getTalentsLikeMe = async (limit: number) => {
  const url = `${BASE_URL}/like-me?limit=${limit}`;

  return httpGet(url);
};

export const findTalents = async (body: { name?: string; email?: string }) => {
  const url = `${BASE_URL}/find`;

  return httpPost({ url, body });
};

export const findTalentsByName = async (name: string): Promise<Talent[]> => {
  return findTalents({ name });
};
