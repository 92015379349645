import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';

import { createInvite, getMyInvites } from './invite.api';

const INVITE_QUERY = 'invite-query';

export const useInviteQuery = ({ enabled = true }) =>
  useQuery(INVITE_QUERY, () => getMyInvites(), {
    enabled,
  });

export const useInviteMutation = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(createInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries([INVITE_QUERY]);
    },
  });
};
