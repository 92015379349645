import { Global, css } from '@emotion/react';

const customStyles = css`
  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    height: 100%;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: HelveticaNowVar, Helvetica, Arial;

    #__next {
      display: flex;
      flex-direction: column;
    }

    // Global fixes

    th {
      font-weight: normal;
    }

    // make sure Safari doesn't show wierd buttons

    textarea,
    input.text,
    input[type='text'],
    input[type='button'],
    input[type='submit'],
    .input-checkbox {
      -webkit-appearance: none !important;
      border-radius: 0;
    }

    // make sure not to show reamaze widget in print
    #reamaze-widget-icon {
      @media print {
        display: none !important;
      }
    }

    // Workaround after radix upgrade to V1.0.0 tooltip
    // this makes sure nothing hides the tooltip content
    [data-radix-popper-content-wrapper] {
      z-index: 1 !important;
    }
  }
`;

const GlobalStyles = () => (
  <>
    <Global styles={customStyles} />
  </>
);

export default GlobalStyles;
