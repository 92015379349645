import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';

import { USER_QUERY } from '../../models/user/user.query';
import { UserDocument } from '../../models/user/user.types';
import { httpGet, httpPost } from '../http/request';

export const ME_QUERY = 'me';
const BASE_URL = 'users/me';

export const getMe = async () => {
  const url = `${BASE_URL}`;

  return httpGet(url);
};

export const updateUser = async (talent: Partial<UserDocument>) => {
  const url = `${BASE_URL}`;

  return httpPost({ url, body: talent });
};

export const useMeQuery = (isEnabled = true) =>
  useQuery<UserDocument, Error>(ME_QUERY, () => getMe(), {
    enabled: isEnabled,
  });

export const useMeMutation = (): UseMutationResult<UserDocument> => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: (talent) => {
      queryClient.invalidateQueries([ME_QUERY]);
      queryClient.invalidateQueries([USER_QUERY, talent._id]);
    },
  });
};
