import { captureException } from '@sentry/nextjs';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  getMyTalent,
  getTalent,
  getTalentsLikeMe,
  updateMyTalent,
} from './talent.api';
import { Talent } from './talent.types';

export const TALENTS_LIKE_ME_QUERY = 'talentsLikeMe';
export const MY_TALENT_QUERY = 'myTalent';
export const TALENT_QUERY = 'talent';

export const useMyTalentQuery = ({ enabled = true } = {}) =>
  useQuery<Talent, Error>(MY_TALENT_QUERY, () => getMyTalent(), {
    enabled,
  });

export const useTalentQuery = (talentId, options = {}) =>
  useQuery<Talent, Error>([TALENT_QUERY, talentId], () => getTalent(talentId), {
    enabled: !!talentId,
    ...options,
  });

export const useMyTalentMutation = (): UseMutationResult<Talent> => {
  const queryClient = useQueryClient();

  return useMutation(updateMyTalent, {
    onSuccess: async (talent) => {
      queryClient.setQueryData([MY_TALENT_QUERY], talent);
      queryClient.setQueryData([TALENT_QUERY, talent._id], talent);
    },
    onError: (e) => {
      captureException(e);
    },
  });
};

export const useTalentsLikeMeQuery = (limit: number) =>
  useQuery<{ talents: Talent[] }, Error>([TALENTS_LIKE_ME_QUERY, limit], () =>
    getTalentsLikeMe(limit),
  );
