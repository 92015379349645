import { httpDelete, httpGet, httpPost } from '../../lib/http/request';

import { Invite } from './invite.types';

export const createInvite = async (invite: Partial<Invite>) =>
  httpPost({
    url: `invites`,
    body: invite,
  });

export const createInviteRequest = async (invite: Partial<Invite>) =>
  httpPost({ url: 'invites/request', body: invite });

export const updateInviteStatus = async (id, status) =>
  httpPost({ url: `invites/${id}`, body: { status } });

export const deleteInvite = async (id) => httpDelete(`invites/${id}`);

export const getMyInvites = async () => httpGet('invites');
