import { AuthProviders } from 'src/auth/auth.types';

export enum SessionType {
  Client = 'client',
  Talent = 'talent',
}

export interface UserDocument {
  _id?: string;
  email?: string;
  full_name?: string;
  avatar_image_url?: string;
  talent_id?: string;
  client_id?: string;
  hashed_password?: string;
  session_type?: SessionType;
  emailVerified?: boolean;
  emailVerificationToken?: string;
  signup_provider?: AuthProviders;
  impersonator?: {
    original_id: string;
    original_session_type: SessionType;
  };
}
