import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';

import { OrganizationMember } from '~models/organization-member/organization-member.types';

import {
  getClientById,
  getClientOrganizationMember,
  updateClient,
} from './client.api';
import { Client } from './client.types';

export const CLIENT_QUERY = 'client';

export const useClientQuery = (clientId: string, isEnabled = true) =>
  useQuery<Client, Error>(
    [CLIENT_QUERY, clientId],
    () => getClientById(clientId),
    { enabled: isEnabled },
  );

export const useClientOrganizationMemberQuery = (
  clientId: string,
  isEnabled = true,
) =>
  useQuery<OrganizationMember, Error>(
    [CLIENT_QUERY, clientId, 'organization-member'],
    () => getClientOrganizationMember(clientId),
    { enabled: isEnabled && !!clientId },
  );

export const useMyClientMutation = (): UseMutationResult<Client> => {
  const queryClient = useQueryClient();

  return useMutation(updateClient, {
    onSuccess: async (client) => {
      await queryClient.invalidateQueries([CLIENT_QUERY, client._id]);
    },
  });
};
