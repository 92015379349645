import { BasicDetails } from '~lib/hooks/use-basic-details';

declare let dataLayer: AnalyticsEvent[];

export interface AnalyticsEvent {
  event: Events;

  [key: string]: string;
}

// TODO: might not be needed with Mixpanel's new API
export enum Actions {
  CLICKED = 'clicked',
  CHECKED = 'checked',
  SELECTED = 'selected',
}

export enum Events {
  // general
  PAGEVIEW = 'pageview',
  START_PROJECT = 'start_project',
  NETWORK_INVITE = 'network_invite',
  NETWORK_CONNECT = 'network_connect',
  NETWORK_DISCONNECT = 'network_disconnect',
  COMPLETE_PROFILE = 'complete_profile',
  FOLLOWING_CLICKED = 'following_clicked',
  FOLLOWERS_CLICKED = 'followers_clicked',
  SEARCH_TALENT_SELECT_FOCUS = 'search_talent_select_focus',
  SEARCH_TALENT_SELECT_CHOICE = 'search_talent_select_choice',

  // Portfolio
  UPLOAD_PROJECT = 'upload_project',
  VIMEO_IMPORT = 'vimeo_import_clicked',
  YOUTUBE_IMPORT = 'youtube_import_clicked',
  ADD_WORK_DISMISS = 'add_work_dismiss',

  ADD_PORTFOLIO_ASSET = 'add_portfolio_asset',

  // Onboarding
  ONBOARDING_STEP = 'onboarding_step',

  // Vetting
  VETTING_PENDING = 'vetting_pending',

  // project setup
  PS_STARTED = 'ps_start_button_clicked',
  PS_CHALLENGE_SELECTED = 'ps_challenge_selected',
  PS_CHANNEL_SELECTED = 'ps_channel_selected',
  PS_BRAND_STRATEGY_SELECTED = 'ps_brand_strategy_selected',
  PS_INDUSTRY_SELECTED = 'ps_industry_selected',
  PS_BRANDS_SELECTED = 'ps_brands_selected',
  PS_TARGET_MARKET_SELECTED = 'ps_target_market_selected',
  PS_TEAM_LOCATION_SELECTED = 'ps_team_location_selected',
  PS_TIMELINE_SELECTED = 'ps_timeline_selected',
  PS_BUDGET_SELECTED = 'ps_budget_selected',
  PS_COMPLETE = 'ps_complete',
  PS_COMPLETE_GUEST = 'ps_complete_guest',

  // Team Builder
  TB_FILTER_BUTTON_CLICKED = 'tb_filter_button_clicked',
  TB_FILTER_INDUSTRY_SELECTED = 'tb_filter_industry_selected',
  TB_FILTER_FRESH_PERSPECTIVE_SELECTED = 'tb_filter_fresh_perspective_selected',
  TB_FILTER_BRAND_SELECTED = 'tb_filter_brand_selected',
  TB_FILTER_TARGET_MARKET_SELECTED = 'tb_filter_target_market_selected',
  TB_FILTER_TEAM_LOCATION_SELECTED = 'tb_filter_team_location_selected',
  TB_FILTER_TIMELINE_TYPE_SELECTED = 'tb_filter_timeline_type_selected',
  TB_FILTER_TIMELINE_EXACT_DATE_SELECTED = 'tb_filter_timeline_exact_date_selected',
  TB_FILTER_BUDGET_SELECTED = 'tb_filter_budget_selected',
  TB_FILTER_SUBMIT = 'tb_filter_submit',

  // Landing page
  JOIN_US = 'join_us',

  // Profile Page
  EDIT_PROFILE = 'edit_profile',

  // PPL you may know
  PYMK_DISCONNECT = 'pymk_disconnect',
  PYMK_CONNECT = 'pymk_connect',

  // Signup
  SIGNUP = 'signup',
  LOGIN = 'login',

  // Invite
  SUBMIT_INVITE_ONLY = 'submit_invite_only',
  INVITE_SEND = 'invite_send',
  INVITE_TALENT = 'invite_talent',

  // createable company
  CREATE_NEW = 'create_new',
  CREATE_NEW_SUBMIT = 'create_new_submit',

  // Team builder page
  TB_PAGE_CLICK_NETWORK_GRAPH = 'tb_page_click_network_graph',
  TB_PAGE_CLICK_BUDGET = 'tb_page_click_budget',
  TB_PAGE_CLICK_BRANDS = 'tb_page_click_brands',
  TB_PAGE_CLICK_AWARDS = 'tb_page_click_awards',
  TB_PAGE_CLICK_ON_PROFILE = 'tb_page_click_profile',
  TB_PAGE_BOOK_TEAM = 'tb_page_book_team',
  TB_PAGE_PROCEED_TO_DASHBOARD = 'tb_page_proceed_to_dashboard',
  TB_PAGE_DOWNLOAD_PDF = 'tb_page_download_pdf',
  TB_PAGE_CLICK_TEAM_NAV_SMALL = 'tb_page_click_team_nav_small',
  TB_PAGE_CLICK_TEAM_NAV_LARGE = 'tb_page_click_team_nav_large',

  // collaboration
  COLLABORATE_REQUEST = 'collaborate_request',
  COLLABORATOR_REJECT = 'collaborate_dismiss',
  COLLABORATOR_ADD = 'collaborate_add',
  COLLABORATOR_EDIT = 'collaborate_edit',
  COLLABORATOR_SAVE = 'collaborate_save',
  COLLABORATOR_CLICK = 'collaborate_click',
  COLLABORATOR_INVITE_SENT = 'collaborate_invite_sent',

  // Portfolio Suggestions
  CLICK_PROJECTS_WIDGET = 'click_projects_widget',

  // Client
  CLIENT_DASHBOARD_COMPLETE_PROFILE = 'client_dashboard_complete_profile',

  // Collective
  COLLECTIVE_DIALOG_APPEAR = 'collective_dialog_appear',
  COLLECTIVE_DIALOG_CLOSED = 'collective_dialog_closed',
  COLLECTIVE_DIALOG_SUBMIT = 'collective_dialog_submit',
  COLLECTIVE_DIALOG_CONNECTION_SUCCESS = 'collective_dialog_connection_success',
  COLLECTIVE_DIALOG_INVITE_SUCCESS = 'collective_dialog_invite_success',
  COLLECTIVE_MANAGEMENT_SUCCESS = 'collective_management_connection_success',
  COLLECTIVE_MANAGEMENT_OPEN = 'collective_management_open',

  COLLECTIVE_PAGE_CLOSE = 'collective_page_close',
  COLLECTIVE_PAGE_APPEAR = 'collective_page_appear',

  // Assignments
  TALENT_ASSIGNMENT_ACCEPT = 'talent_assignment_accept',
  TALENT_ASSIGNMENT_REJECT = 'talent_assignment_reject',
  TALENT_CLICKED_TEAM_MEMBER = 'talent_clicked_team_member',
  TALENT_ASSIGNMENT_PAGE_IS_SHOWN = 'talent_assignment_page_is_shown',

  // Website
  BOOK_DEMO = 'book_demo',
  KYD_FORM_SUBMIT = 'kyd_form_submit',
  VIDEO_PREVIEW_CLICK = 'video_preview_click',

  IMPORT_FROM_LINKEDIN_CLICK = 'import_from_linkedin_click',
  IMPORT_FROM_LINKEDIN_SUCCESS = 'import_from_linkedin_success',
  IMPORT_FROM_LINKEDIN_FAILURE = 'import_from_linkedin_failure',
}

export type CreationTypes = 'brands' | 'client_name' | 'agency' | 'banner';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isMixpanelLoaded = (): boolean => window?.mixpanel?.__loaded;

export const registerUserToEvents = (userBasicDetails: BasicDetails) => {
  if (isMixpanelLoaded()) {
    const { userId, email, fullName, clientId, talentId, sessionType } =
      userBasicDetails;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.mixpanel.register({
      userId,
      email,
      fullName,
      clientId,
      talentId,
      sessionType,
    });
  }
};

export const dispatchAnalyticsEvent = (log: AnalyticsEvent) => {
  // We don't want to dispatch analytics events if we're in development mode or Storybook
  // As well as we must check if the dataLayer is defined on the window
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (window?.dataLayer) {
    dataLayer?.push(log);
  }

  /**
   * Mixpanel is loaded on the global window object via GTM
   */
  if (isMixpanelLoaded()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.mixpanel.track(log.event, log);
  }
};

enum OnboardingSteps {
  ONBOARDING_BASIC_DETAILS = 'basic_details',
  ONBOARDING_EXPERIENCE = 'experience_step',
  ONBOARDING_PROFESSIONAL_DETAILS = 'professional_details',
  ONBOARDING_DAILY_RATE = 'daily_rate',
}

const ONBOARDING_STEPS = [
  OnboardingSteps.ONBOARDING_BASIC_DETAILS,
  OnboardingSteps.ONBOARDING_PROFESSIONAL_DETAILS,
  OnboardingSteps.ONBOARDING_EXPERIENCE,
  OnboardingSteps.ONBOARDING_DAILY_RATE,
];

export const onboardingStepLog = (page: number) => {
  if (ONBOARDING_STEPS[page - 1]) {
    dispatchAnalyticsEvent({
      event: Events.ONBOARDING_STEP,
      label: ONBOARDING_STEPS[page - 1],
      action: Actions.CLICKED,
    });
  }
};

export const pageview = (url) => {
  dispatchAnalyticsEvent({
    event: Events.PAGEVIEW,
    page: url,
    pageTitle: document.title,
  });
};
