import { useEffect, useState } from 'react';

import { AppProps } from 'next/app';

import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { setUser } from '@sentry/nextjs';
import { LazyMotion } from 'framer-motion';
import { NextPage } from 'next';
import { SessionProvider, SessionProviderProps } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import { HydrateProps, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';

import { useBasicDetails } from '~lib/hooks/use-basic-details';
import { registerUserToEvents } from '~lib/loggers/events-recorder';

import { Toaster } from '../components/common/toaster/toaster';
import { usePageView } from '../lib/hooks/use-page-view';
import GlobalStyles from '../styles/GlobalStyles';

import { seoConfig } from 'next-seo.config';

import '../../public/webFonts/MyFontsWebfontsKit.css';
import '../styles/global.css';

export type NextPageWithLayout<Props = Record<string, unknown>> =
  NextPage<Props> & {
    Layout?: React.ComponentType;
  };

type AppPropsWithLayout = AppProps<{
  session: SessionProviderProps['session'];
  dehydratedState: HydrateProps['state'];
}> & {
  Component: NextPageWithLayout;
};

const STALE_TIME =
  parseInt(process.env.NEXT_PUBLIC_QUERY_STALE_MILISECONDS) || 60000;

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  usePageView();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: STALE_TIME,
          },
        },
      }),
  );

  return (
    <TooltipProvider>
      <LazyMotion
        features={() =>
          import('./../lib/motion-features').then((res) => res.default)
        }
      >
        <QueryClientProvider client={queryClient}>
          <SessionProvider
            session={pageProps?.session}
            refetchInterval={15 * 60}
          >
            <RegisterUserForEvents>
              <Hydrate state={pageProps?.dehydratedState}>
                <GlobalStyles />
                  <DefaultSeo {...seoConfig} />
                  <>
                    {Component.Layout ? (
                      <Component.Layout>
                        <Component {...pageProps} />
                      </Component.Layout>
                    ) : (
                      <Component {...pageProps} />
                    )}
                    <Toaster />
                  </>
              </Hydrate>
            </RegisterUserForEvents>
          </SessionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LazyMotion>
    </TooltipProvider>
  );
};

const RegisterUserForEvents = ({ children }) => {
  const basicUserDetails = useBasicDetails();

  useEffect(() => {
    if (basicUserDetails) {
      registerUserToEvents(basicUserDetails);

      setUser({
        email: basicUserDetails.email,
        id: basicUserDetails.userId,
        username: basicUserDetails.fullName,
      });
    }
  }, [basicUserDetails, basicUserDetails?.userId, basicUserDetails.fullName]);

  return children;
};

export default App;
