import { useSession } from 'next-auth/react';

import { useClientQuery } from '~models/client/client.query';
import { Client } from '~models/client/client.types';
import { useInviteQuery } from '~models/invite/invite.query';
import { useMyTalentQuery } from '~models/talent/talent.query';
import { Talent } from '~models/talent/talent.types';
import { SessionType } from '~models/user/user.types';

import { useMeQuery } from '../queries/me';

export interface BasicDetails {
  userId: string;
  email: string;
  fullName: string;
  clientId: string;
  avatarImageUrl: string;
  isLoggedIn: boolean;
  isVetted: boolean;
  sessionType: SessionType;
  talentId: string;
  talent?: Talent;
  client?: Client;
  canSendInvites?: boolean;
  impersonator?: boolean;
}

export const useBasicDetails = () => {
  const { data: session, status } = useSession();
  const isSessionLoading = status === 'loading';
  const { data: currentUser, isLoading: isLoadingMe } = useMeQuery(
    !isSessionLoading && !!session,
  );

  const isLoggedIn = Boolean(session && currentUser?._id && !isLoadingMe);

  const enableQueries = currentUser?.session_type === SessionType.Talent;
  const { data: myTalent } = useMyTalentQuery({
    enabled: enableQueries,
  });

  const { data: invites } = useInviteQuery({ enabled: enableQueries });

  const enableClientQueries = currentUser?.session_type === SessionType.Client;

  const { data: client } = useClientQuery(
    currentUser?.client_id,
    enableClientQueries,
  );

  const {
    _id: userId,
    talent_id: talentId,
    session_type: sessionType,
    client_id: clientId,
    email,
  } = currentUser || {};

  let { full_name: fullName, avatar_image_url: avatarImageUrl } =
    currentUser || {};

  if (currentUser?.session_type === SessionType.Talent) {
    fullName = myTalent?.full_name || fullName;
    avatarImageUrl = myTalent?.avatar_image_url || avatarImageUrl;
  }

  if (currentUser?.session_type === SessionType.Client) {
    fullName = client?.full_name || fullName;
    avatarImageUrl = client?.avatar_image_url || avatarImageUrl;
  }

  const currentSession: BasicDetails = {
    userId,
    email,
    fullName,
    clientId,
    avatarImageUrl,
    isLoggedIn,
    isVetted: myTalent?.is_vetted,
    sessionType,
    talentId,
    talent: myTalent,
    client,
    canSendInvites: Number.isInteger(invites?.length) && invites?.length < 3,
    impersonator: Boolean(currentUser?.impersonator),
  };

  return currentSession;
};
