import { OrganizationMember } from '~models/organization-member/organization-member.types';

import { httpGet, httpPost } from '../../lib/http/request';

import { Client } from './client.types';

const BASE_URL = 'clients';

export const getClientById = async (clientId: string): Promise<Client> => {
  const url = `${BASE_URL}/${clientId}`;

  return httpGet(url);
};

export const getClientOrganizationMember = async (
  clientId: string,
): Promise<OrganizationMember> => {
  const url = `${BASE_URL}/${clientId}/organization-member`;

  return httpGet(url);
};

export const updateClient = async (client: Partial<Client> = {}) => {
  const url = `${BASE_URL}/${client._id}`;

  return httpPost({ url, body: client });
};
