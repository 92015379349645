import { useQuery } from 'react-query';

import { httpGet } from '../../lib/http/request';
import { Talent } from '../talent/talent.types';

const BASE_URL = 'users';

export const getUser = async (userId: string | string[]) => {
  const url = `${BASE_URL}/${userId}`;

  return httpGet(url);
};

export const USER_QUERY = 'user';

export const useUserQuery = (userId) =>
  useQuery<Talent, Error>([USER_QUERY, userId], () => getUser(userId));
