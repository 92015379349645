import { DefaultSeoProps } from 'next-seo';

export const seoConfig: DefaultSeoProps = {
  openGraph: {
    type: 'Organization',
    locale: 'en_IE',
    url: 'https://togetherr.com',
    site_name: 'togetherr',
    images: [
      {
        url: 'https://togetherr.com/og-image.png',
        width: 800,
        height: 600,
        alt: 'We are an idea | togetherr',
        type: 'image/png',
      },
    ],
  },
  twitter: {
    handle: '@togetherrcom',
    site: 'https://togetherr.com',
    cardType: 'summary_large_image',
  },
  titleTemplate: '%s | togetherr',
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
  ],
  additionalMetaTags: [
    { name: 'msapplication-TileColor', content: '#da532c' },
    { name: 'theme-color', content: '#ffffff' },
    {
      name: 'google-site-verification',
      content: 'hQ0fWRPRcRSfJ-34dtuO6hW8f81lEercSqQb2H5HpyM',
    },
  ],
};
